import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import ExamplePageOne from '../Example';
import ExamplePageTwo from '../ExampleTwo/index';
import MainCourt from '../MainCourt/index';
import CourtType from '../CourtType/index';
import CourtCategory from '../CourtCategory';
import Court from '../Court';
import Addons from '../Addons';
import AllCourts from '../AllCourts';
import AllCourtsEdit from '../AllCourtsEdit';
import EditCourtTypeCard from '../EditCourtType';
import ListCourtTypeCard from '../ListCourtType';
import EditCourtCategory from '../EditCourtCategory';
import EditAddons from '../EditAddons';
import ListAddons from '../ListAddons';
import ListIndex from '../ListMainCourt';
import EditMainCourtIndex from '../EditMainCourt';
import UsersIndex from '../Users';
import BookingIndex from '../Booking Details';
import ListCourtCategory from '../ListCourtCategory';
import BookingCancel from '../Booking Cancellation Details';
import Rating from '../Rating';
import ListSlides from '../ListSlides';
import Slides from '../Slides';
import EditSlides from '../EditSlides';
import Padelholic from '../Padelholic';
import ListPadelholic from '../ListPadelholic';
import EditPadelholic from '../EditPadelholic';
import ManageTimeSlot from '../ManageTimeSlot';
import CourtBooking from '../CourtBooking';
import CourtBookingSingle from '../CourtBookingSingle';
import AddonsBooking from '../AddonsBooking';
import CustomerDetails from '../CustomerDetails';
import Role from '../Role';
import CreateUser from '../CreateUser';
import Calender from '../Calender';
import NotifyMe from '../NotifyMe';
import Transactions from '../Transactions';
import AllCourtsViewDetails from '../AllCourtsViewDetails';
import MainCourtsViewDetails from '../MainCourtsViewDetails';
import Coupon from '../Coupon';
import UserRatings from '../UserRating/';
import CreateCoupon from '../CreateCoupon';
import EditCoupon from '../EditCoupon';
import EditRole from '../EditRole';
import RoleLists from '../RoleList/components/RoleLists';
import Report from '../Report';
import UserDetails from '../UserDetails';
import Refund from '../Refund';
import Table from '../DataTable';
import Resize from '../ResizableTable';
import Getpage from '../GetPage/GetPage';
import AddPage from '../GetPage/AddPage';
import EditPage from '../GetPage/EditPage';
import TransactionDetails from '../TransactionDetails';
import ViewBookingDetails from '../ViewBookingDetails';
import AddOffer from '../AddOffer';
import EditOffer from '../EditOffer';
import OfferList from '../OfferList';
import Miscellenious from '../Miscellenious';
import MiscelleniousList from '../MiscelleniousList';
import MiscelleniousEdit from '../MiscelleniousEdit';
import PushNotification from '../PushNotification';
import Promotions from '../Promotions';
import Package from '../../Package/Package';
import AddPromotions from '../AddPromotions';
import EditPromotion from '../EditPromotion';
import WalletTransactions from '../WalletTransaction';
import NewAddOffer from '../AddOffer/components/NewAddOffer';
import User from '../User/User';
import Quickpay from '../Quickpay/Quickpay';
import QuickPayDetails from '../Quickpay/QuickPayDetails';
import Tournaments from '../Tournaments/Tournaments';
import EmailConfiguration from '../ResetPassword/EmailConfiguration';
import OTPVerification from '../ResetPassword/OTPVerification';
import ResetPassword from '../ResetPassword/ResetPassword';
import AdSpace from '../AdSpace'
import AdSpaceList from '../AdSpace/components/AdSpace-list';
import EditAdSpace from '../AdSpace/components/EditAdSpace';
import CreateNews from '../News/CreateNews';
import GetNews from '../News/GetNews';
import PackageList from '../../Package/PackageList';
import EditPackages from '../../Package/EditPackages';
import EditNews from '../News/EditNews';
import ListLTO from '../LimitedTimeOffer/ListLTO';
import CreateLTO from '../LimitedTimeOffer/CreateLTO';
import EditLTO from '../LimitedTimeOffer/EditLTO';
import UserDetailsCard from '../UserDetails/components/UserDetailsCard';
import ExamplePage from '../UserDetails';
import AdsImpressions from '../AdsImpressions/AdsImpressions';
import UserPackages from '../UserPackages/UserPackages';
import DownloadExcel from '../Booking Details/components/DownloadExcel';
import CalendarSkaleton from '../Skaleton/CalendarSkaleton';
import LivePost from '../Padelgram/LivePost';
import ReportProblem from '../Padelgram/Report';
import DeletedPost from '../Padelgram/DeletedPost/DeletedPost';
import BulkPromo from '../AddPromotions/components';
import KidsSummerCamp from '../KidsSummerCamp';
import Calenderv2 from '../Calenderv2/Calenderv2';
import CreateRewards from '../Rewards/CreateRewards';
import RewardsList from '../Rewards/RewardsList';
import EditRewards from '../Rewards/EditRewards';
import PromotionsV2 from '../PromotionsV2/components/PromotionsV2';
import Promotionv2index from '../PromotionsV2';
import PromotionV2List from '../PromotionsV2/PromotionV2List';
import Unsuccess from '../UnsuccessfulTransactions/Unsuccess';
import EditPromotionV2 from '../PromotionsV2/EditPromotionV2';
import Coaches from '../Coaches/Coaches';
import CoachesList from '../Coaches/CoachesList';
import EditCoaches from '../Coaches/EditCoaches';
import Staff from '../Staff/Staff';
import StaffList from '../Staff/StaffList';
import EditStaff from '../Staff/EditStaff';
import ManageStaffSlots from '../Staff/ManageStaffSlots';
import StaffCalender from '../Staff/StaffCalender';
import ManageCoachesSlots from '../Coaches/ManageCoachesSlots';
import CoachesCalender from '../Coaches/CoachesCalender';
import GiftCard from '../GiftCard/GiftCard';
import LoyalityPointsManagement from '../LoyalityPoints/LoyalityPointsManagement';
import GenerateSlot from '../GenerateSlots/GenerateSlot';
import CategoriesList from '../MatchCategories/CategoriesList';
import UploadPost from '../Padelgram/UploadPost';
import VerifySkillLevel from '../VerifySkillLevel/VerifySkillLevel';
import List_SlidesV2 from '../ListSlidesV2';
import AddSlidesV2 from '../AddSlidesV2';
import EditSlidesV2 from '../EditSlideV2';
import { GetAvailableSlots } from '../GetAvailableSlots/GetAvailableSlots';
import { GetDoubleBookings } from '../GetDoubleBookings/GetDoubleBookings';
import { DoubleBookingList } from '../GetDoubleBookings/DoubleBookingList';
import { CoupanDocs } from '../CoupanDocs/CoupanDocs';
import { CouponOffers } from '../CouponOffers/CouponOffers';
import { CreateCouponOffer } from '../CouponOffers/CreateCouponOffer';
import { EditCouponOffer } from '../CouponOffers/EditCouponOffer';
import { AddOffersCard } from '../Cards/AddOffersCard';
import { PriceConfigurations } from '../PriceConfigurations/PriceConfigurations';
import { EditPriceConfigurations } from '../PriceConfigurations/EditPriceConfigurations';
import { CouponDocsStatus } from '../CouponDocsStatus/CouponDocsStatus';
import { GetSlotsBookingReport } from '../GetSlotsBookingReport/GetSlotsBookingReport';
import { EventTournamentRegistration } from '../EventTournamentRegistration/EventTournamentRegistration';
import { AddEventTournament } from '../EventTournamentRegistration/AddEventTournament';
import { EditEventTournament } from '../EventTournamentRegistration/EditEventTournament';
import { ViewEventTournament } from '../EventTournamentRegistration/ViewEventTournament';
import { EventRegistrationTabs } from '../EventTournamentRegistration/EventRegistrationTabs';
import { useSelector, useDispatch } from 'react-redux'
import CouponGroupDetails from '../Coupon/components/CouponGroupDetails';
import CalenderV3 from '../Calenderv2/CalenderV3';
import BookingDetailsV2 from '../Booking Details/BookingDetailsV2';
import UpdateUserDetails from '../UserDetails/components/UpdateUserDetails';
import QuickPayPaymentList from '../Quickpay/QuickPayPaymentList';
import TransactionsList from '../Transactions/TransactionsList';
import CouponBookingList from '../Coupon/components/CouponBookingList';
import AdminUsers from '../Users/AdminUsers';

//components/Promotions
export const Pages = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Routes>
        <Route path="/" element={<BookingDetailsV2 />} />
        <Route path="/one" element={<ExamplePageOne />} />
        <Route path="/two" element={<ExamplePageTwo />} />
        <Route path="/main_court" element={<MainCourt />} />
        <Route path="/list_main_court" element={<ListIndex />} />
        <Route path="/edit_main_court/:id" element={<EditMainCourtIndex />} />
        <Route path="/court_type" element={<CourtType />} />
        <Route path="/court_category" element={<CourtCategory />} />
        <Route path="/court_court" element={<Court />} />
        <Route path="/court_addons" element={<Addons />} />
        <Route path="/all_courts" element={<AllCourts />} />
        <Route path="/edit_addons/:id" element={<EditAddons />} />
        <Route path="/list_addons" element={<ListAddons />} />
        <Route path="/edit_courts/:id" element={<AllCourtsEdit />} />
        <Route path="/edit_court_type/:id" element={<EditCourtTypeCard />} />
        <Route path="/list_court_type" element={<ListCourtTypeCard />} />
        <Route path="/edit_court_category/:id" element={<EditCourtCategory />} />
        <Route path="/user" element={<UsersIndex />} />
        <Route path="/admin-users" element={<AdminUsers />} />
        <Route path="/bookings" element={<BookingDetailsV2 />} />
        <Route path="/bookings-v2" element={<BookingDetailsV2 />} />
        <Route path="/bookings_details/:id" element={<ViewBookingDetails />} />
        <Route path="/list_court_category" element={<ListCourtCategory />} />
        <Route path="/booking_cancel" element={<BookingCancel />} />
        <Route path="/slides" element={<Slides />} />
        <Route path="/list_slides" element={<ListSlides />} />
        <Route path="/edit_slides/:id" element={<EditSlides />} />
        <Route path="/list_slides-v2" element={<List_SlidesV2 />} />
        <Route path="/add_slides-v2" element={<AddSlidesV2 />} />
        <Route path="/edit_slides-v2/:id" element={<EditSlidesV2 />} />
        <Route path="/rating" element={<Rating />} />
        <Route path="/padelholic" element={<Padelholic />} />
        <Route path="/listpadelholic" element={<ListPadelholic />} />
        <Route path="/editpadeholic/:id" element={<EditPadelholic />} />
        <Route path="/managetimeslot/:id" element={<ManageTimeSlot />} />
        <Route path="/courtbooking" element={<CourtBooking />} />
        <Route path="/book_court/:id" element={<CourtBookingSingle />} />
        <Route path="/addon_booking" element={<AddonsBooking />} />
        <Route path="/customer_details" element={<CustomerDetails />} />
        <Route path="/role" element={<Role />} />
        <Route path="/create_user" element={<CreateUser />} />
        <Route path="/calender" element={<Calender />} />
        <Route path="/notify_me" element={<NotifyMe />} />
        <Route path="/transactions" element={<TransactionsList  />} />
        <Route path="/transactions-old" element={<Transactions  />} />
        <Route path="/transactions2" element={<TransactionsList />} />
        <Route path="/unsucess-transaction" element={<Unsuccess />} />
        <Route path="/transactions_details/:id" element={<TransactionDetails />} />
        <Route path="/all_courts_view_details/:id" element={<AllCourtsViewDetails />} />
        <Route path="/main_courts_view_details/:id" element={<MainCourtsViewDetails />} />
        <Route path="/coupon" element={<Coupon />} />
        <Route path="/coupon-booking-list" element={<CouponBookingList />} />
        <Route path="/coupon-group/:id" element={<CouponGroupDetails />} />
        <Route path='/coupon-offers' element={<CouponOffers />} />
        <Route path="/add_coupons" element={<CreateCoupon />} />
        <Route path="/add_coupons_offer" element={<CreateCouponOffer />} />
        <Route path="/edit_coupons/:id" element={<EditCoupon />} />
        <Route path="/edit-coupon-offers/:id" element={<EditCouponOffer />} />
        <Route path="/user_ratings" element={<UserRatings />} />
        <Route path="/edit_role/:id" element={<EditRole />} />
        <Route path="/role_list" element={<RoleLists />} />
        <Route path="/report" element={<Report />} />
        <Route path="/user_details/:id" element={<ExamplePage />} />
        <Route path="/update-user-details" element={<UpdateUserDetails />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/table" element={<Table />} />
        <Route path="/resize" element={<Resize />} />
        <Route path="/getpage" element={<Getpage />} />
        <Route path="/edit_page/:name" element={<EditPage />} />
        <Route path="/Add_page/" element={<AddPage />} />
        <Route path='/offer-list' element={<OfferList />} />
        <Route path='/add-offer' element={<AddOffer />} />
        <Route path='/edit-offer/:id' element={<EditOffer />} />
        <Route path="/misc" element={<Miscellenious />} />
        <Route path="/misc-list" element={<MiscelleniousList />} />
        <Route path="/misc-edit/:id" element={<MiscelleniousEdit />} />
        <Route path="/push_notification" element={<PushNotification />} />
        <Route path="/promotion" element={<Promotions />} />
        <Route path="/package" element={<Package />} />
        <Route path="/package-list" element={<PackageList />} />
        <Route path="/packages/:packageId" element={<EditPackages />} />
        <Route path="/add-promotions" element={<AddPromotions />} />
        <Route path="/edit-promotions/:id" element={<EditPromotion />} />
        <Route path="/wallet-transaction" element={<WalletTransactions />} />
        <Route path="/newoffer" element={<NewAddOffer />} />
        <Route path="/update-user" element={<User />} />
        <Route path="/quick-pay" element={<Quickpay />} />
        <Route path="/quick-pay-details-old" element={<QuickPayDetails />} />
        <Route path="/quick-pay-details" element={<QuickPayPaymentList />} />
        <Route path="/tournaments" element={<Tournaments />} />
        <Route path="/ad-space" element={<AdSpace />} />
        <Route path="/ad-space-list" element={<AdSpaceList />} />
        <Route path="/edit-adSpace/:adsId" element={<EditAdSpace />} />
        <Route path="/create-news" element={<CreateNews />} />
        <Route path="/news-list" element={<GetNews />} />
        <Route path="/edit-news/:newsId" element={<EditNews />} />
        <Route path="/limited_time_offers" element={<ListLTO />} />
        <Route path="/create_limited_time_offers" element={<CreateLTO />} />
        <Route path="/edit_limited_time_offers/:offerId" element={<EditLTO />} />
        <Route path="/ads-impressions" element={<AdsImpressions />} />
        <Route path="/user-packages" element={<UserPackages />} />
        <Route path="/download-excel" element={<DownloadExcel />} />
        <Route path="/CalendarSkaleton" element={<CalendarSkaleton />} />
        <Route path="/live-post" element={<LivePost />} />
        <Route path="/report-problem" element={<ReportProblem />} />
        <Route path="/deleted-post" element={<DeletedPost />} />
        <Route path="/bulk-promotion" element={<BulkPromo />} />
        <Route path="/kids-summer-camp" element={<KidsSummerCamp />} />
        <Route path="/calenderv2" element={<Calenderv2 />} />
        <Route path="/calenderv3" element={<CalenderV3 />} />
        <Route path="/rewards-list" element={<RewardsList />} />
        <Route path="/add-rewards" element={<CreateRewards />} />
        <Route path="/edit-rewards/:rewardId" element={<EditRewards />} />
        <Route path="/promotionsv2" element={<PromotionsV2 />} />
        <Route path="/promotionsv2-list" element={<PromotionV2List />} />
        <Route path="/editpromotionsv2/:id" element={<EditPromotionV2 />} />
        <Route path="/promotionv2index" element={<Promotionv2index />} />
        <Route path="/coaches" element={<Coaches />} />
        <Route path="/coaches-list" element={<CoachesList />} />
        <Route path="/edit-coaches/:coachId" element={<EditCoaches />} />
        <Route path="/customer-support" element={<Staff />} />
        <Route path="/customer-support-list" element={<StaffList />} />
        <Route path="/edit-customer-support/:staffId" element={<EditStaff />} />
        <Route path="/manage-staff-slots/:staffId" element={<ManageStaffSlots />} />
        <Route path="/customer-support-calender" element={<StaffCalender />} />
        <Route path="/manage-coache-slots/:coacheId" element={<ManageCoachesSlots />} />
        <Route path="/coache-calender" element={<CoachesCalender />} />
        <Route path="/e-giftcard" element={<GiftCard />} />
        <Route path="/loyality-points-management" element={<LoyalityPointsManagement />} />
        <Route path="/generate-slot" element={<GenerateSlot />} />
        <Route path="/get-availableslots" element={<GetAvailableSlots />} />
        <Route path="/get-doublebookings" element={<GetDoubleBookings />} />
        <Route path='/doublebooking-list' element={<DoubleBookingList />} />
        <Route path="/get-slotsbookingreport" element={<GetSlotsBookingReport />} />
        <Route path="/match-categories-list" element={<CategoriesList />} />
        <Route path="/upload-post" element={<UploadPost />} />
        <Route path="/verify-skill-level" element={<VerifySkillLevel />} />
        <Route path="/offer-docs" element={<CouponDocsStatus />} />
        <Route path="/cards" element={<AddOffersCard />} />
        <Route path="/price-configurations" element={<PriceConfigurations />} />
        <Route path="/edit-price-configurations/:id" element={<EditPriceConfigurations />} />
        <Route path='/event-registration-tabs' element={<EventRegistrationTabs />} />
        <Route path='/event-tournaments-registration' element={<EventTournamentRegistration />} />
        <Route path='/add-event-tournament' element={<AddEventTournament />} />
        <Route path='/edit-event-tournament/:id' element={<EditEventTournament />} />
        <Route path='/view-event-tournament/:id' element={<ViewEventTournament />} />
        <Route path="/offer-docs" element={<CouponDocsStatus />} />
        <Route path="/loyalty-points-management" element={<LoyalityPointsManagement />} />
      </Routes>
    </div>
  </div>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="" element={<Pages />} />
    </div>
  </div>
);

const UnAuthenticatedRoutes = () => (
  <Routes>
    <Route path="/" element={<LogIn />} />
    <Route path="/log_in" element={<LogIn />} />
    <Route path="/verify_email" element={<EmailConfiguration />} />
    <Route path="/verify_otp" element={<OTPVerification />} />
    <Route path="/reset_password" element={<ResetPassword />} />
  </Routes>
)


const Router = () => {
  const LoggedUser = JSON.parse(localStorage.getItem("loggedUserDetails"));
  const authslice = useSelector((state) => state.auth);
  return (
    <MainWrapper>
      <main>
        {authslice?.isLogged ? <Pages /> : <UnAuthenticatedRoutes />}
      </main>
    </MainWrapper>
  )
};

export default Router;
